<template>
  <div>
    <vs-card>
      <div slot="header">
        <h5>List Type</h5>
      </div>

      <ul class="leftx">
        <li v-for="(scope, index) in scopes" :key="index">
          <vs-radio
            v-model="selected_scope"
            vs-name="scopes"
            :vs-value="scope"
            >{{ scope }}</vs-radio
          >
        </li>
      </ul>

      <div slot="footer">
        <vs-row vs-justify="center">
          <vs-button type="gradient" color="primary" @click="fetchData(1)"
            >Get</vs-button
          >
        </vs-row>
      </div>
    </vs-card>

    <vs-card>
      <div slot="header">
        <h5>Search</h5>
      </div>
      <div class="leftx labelx">
        <!-- <vs-input
          v-for="(searchOption, index) in searches.textInputs"
          :key="index"
          :label="searchOption.placeholder"
          v-model="params[searchOption.vModel]"
        /> -->
        <vs-input class="searchInputFields" label="Can-ID" v-model="can_id" />
        <vs-input class="searchInputFields" label="Name" v-model="name" />
        <vs-input class="searchInputFields" label="Email" v-model="email" />
        <vs-input class="searchInputFields" label="Mobile" v-model="mobile" />
        <vs-input
          class="searchInputFields"
          label="Courier Number"
          v-model="courier_number"
        />
      </div>

      <div slot="footer">
        <vs-row vs-justify="center">
          <vs-button
            type="gradient"
            style="margin-right: 1%"
            color="dark"
            icon="refresh"
            @click="refresh()"
          ></vs-button>
          <vs-button type="gradient" color="primary" @click="fetchData(1, true)"
            >Search</vs-button
          >
        </vs-row>
      </div>
    </vs-card>
  </div>
</template>

<script>
import axios from "axios";
import constants from "../../../constants.json";
import eventbus from "../eventbus";
export default {
  mounted() {
    this.fetchData(1);
    eventbus.$on("reload-warehouse-purchases", () => {
      this.fetchData(1);
    });
  },
  props: ["current_page", "course"],
  watch: {
    current_page(page) {
      this.fetchData(page);
    },
  },
  data() {
    return {
      selected_scope: "All",
      scopes: [
        "All",
        // "Send Books",
        // "Add Courier Number",
        // "Mark Delivered",
        "To Be Processed",
        "Delivered",
      ],
      can_id: null,
      name: null,
      email: null,
      mobile: null,
      courier_number: null,
      // searches: {
      //   textInputs: [
      //     {
      //       placeholder: "Can-ID",
      //       vModel: "can_id",
      //     },
      //     {
      //       placeholder: "Name",
      //       vModel: "name",
      //     },
      //     {
      //       placeholder: "Email",
      //       vModel: "email",
      //     },
      //     {
      //       placeholder: "Mobile",
      //       vModel: "mobile",
      //     },
      //     {
      //       placeholder: "Courier Number",
      //       vModel: "courier_number",
      //     },
      //   ],
      // },
      // params: {},
    };
  },
  methods: {
    refresh() {
      this.selected_scope = "All";
      this.name = null;
      this.can_id = null;
      this.email = null;
      this.mobile = null;
      this.courier_number = null;
      this.fetchData(1);
    },
    fetchData(page, individualSearch) {
      this.$vs.loading();
      let params = {
        list_type: this.getListType(),
        name: this.name,
        can_id: this.can_id,
        email: this.email,
        mobile: this.mobile,
        courier_number: this.courier_number,
        course: this.course,
        page: page,
      };
      if (individualSearch) {
        params.list_type = null;
        this.selected_scope = "All";
      }
      axios
        .get(`${constants.SERVER_API}getWarehouseDashboard`, {
          params: params,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$vs.loading.close();
          if (response.data.status !== "success") {
            this.handleNotification(response);
          } else {
            this.$emit("fetchedData", response);
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    getListType() {
      let value = null;
      switch (this.selected_scope) {
        case "To Be Processed":
          value = 1;
          break;
        case "Delivered":
          value = 2;
          break;
        default:
          value = null;
          break;
      }
      return value;
    },
  },
};
</script>

<style>
.searchInputFields {
  width: 100%;
}
</style>
